import Header from "../../components/header/Header";
import "./Landing.css";
import DevImage from "../../assets/developers_image.svg";
import CalanderIcon from "../../assets/calendar.svg";
// import NavigationIcon from "../../assets/navigation.svg";
import AboutUsImg from "../../assets/aboutUsImage.png";
import ServiceDiagram from "../../assets/service_diagram.png";
import Instagram from "../../assets/instagram.svg";
import Linkedin from "../../assets/linkedin.svg";
import Twitter from "../../assets/twitter.svg";
import MessageSquare from "../../assets/message-square.svg";
import ScalixSymbol from "../../assets/scalix_symbole.png";
import Dashboard from "../../assets/dashboard.png";
import Feature_icon1 from "../../assets/feature_icon1.png";
import Feature_icon2 from "../../assets/feature_icon2.png";
import Feature_icon3 from "../../assets/feature_icon3.png";
import Feature_icon4 from "../../assets/feature_icon4.png";
import Feature_icon5 from "../../assets/feature_icon5.png";
import Feature_icon6 from "../../assets/feature_icon6.png";
import Feature_icon7 from "../../assets/feature_icon7.png";
import Feature_icon8 from "../../assets/feature_icon8.png";
import Feature_icon9 from "../../assets/feature_icon9.png";
import Vector from "../../assets/vector.svg";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  // phoneNumber: Yup.string().required("Phone Number is required"),
});

interface FormType {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
}

const sheet_url =
  "https://script.google.com/macros/s/AKfycbxb4XG5laAqYg6yI5fwspX15bxD5ffgCYLMOCT3T7uyhagZWa8pL3s0uTL9I4PH0w4/exec";

const Landing = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };

  const scheduleConsultation = () => {
    window.open("https://calendar.app.google/Kem2AdAjHQXZxDWN9", "_blank");
  };

  const handleSubmit = async (values: FormType, actions: any) => {
    const params = {
      ...values,
      first_name: values.firstName,
      last_name: values.lastName,
    };

    const queryString = new URLSearchParams(params).toString();

    const response = await fetch(`${sheet_url}?${queryString}`, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
    });

    await response.text();
    actions.resetForm();
  };
  return (
    <div className="overflow-x-hidden">
      <div className="header-bg">
        <Header></Header>
        <div className="content-wrapper mb-[226px] mt-[118px] flex flex-col-reverse justify-between md:flex-row">
          <div className="text-center md:text-left">
            <div className="mb-3 mt-10 w-[420px] text-[40px] font-bold leading-[45px] text-white md:mt-0 md:text-[56px] md:leading-[67px]">
              Where <span className="ideas"> Ideas </span> <br />
              Transform Into
              <br />
              <span className="software"> Software </span>
            </div>
            <div className="mb-6.5 w-auto px-6 text-base font-normal leading-[19px] text-white md:w-[420px] md:px-0">
              We’re dedicated to pushing the boundaries of technology and
              creating software solutions that make a difference.
            </div>
            <div
              className="btn btn-primary m-auto flex w-[295px] cursor-pointer justify-center gap-4 md:ml-0"
              onClick={() => scheduleConsultation()}
            >
              <div className="font-inria text-base font-bold leading-[19px]">
                Schedule a Consultation
              </div>
              <div>
                <img src={CalanderIcon} alt="" width={18} height={18} />
              </div>
            </div>
          </div>
          <div className="m-auto mt-[-120px] h-[200px] w-[250px] md:mx-8 md:mt-[-85px] md:h-[475px] md:w-[525px]">
            <img src={DevImage} alt="" />
          </div>
        </div>
      </div>
      {/* About us section */}
      <div className="content-wrapper flex flex-col items-center gap-[85px] pt-10 md:flex-row md:py-[102px]">
        <div>
          <img src={AboutUsImg} alt="" width={525} height={378} />
        </div>
        <div className="w-auto px-4 md:w-[420px] md:px-0 md:py-8">
          <div className="mb-3 text-center text-[38px] font-bold leading-[46px] text-[#151515] md:text-left">
            Transforming Complexity into Simplicity
          </div>
          <div className="mb-6.5 text-base font-normal leading-[19px] text-[#232323]">
            Welcome to Scalix Enterprise Solution LLP, where technology meets
            innovation, and solution are crafted to transform businesses. Since
            our inception, we've been driven by a singular mission: to empower
            organizations with tailer-made software solutions that drive growth,
            efficiency and success.
          </div>
          {/* <div className="font-inria btn btn-primary flex w-[190px] items-center justify-center gap-4 text-base leading-[19px]">
            <div>Explore More</div>
            <div>
              <img src={NavigationIcon} alt="" width={18} height={18} />
            </div>
          </div> */}
        </div>
      </div>
      {/* diagram section */}
      <div className="honeycomb w-full md:h-[837px] ">
        <div className="content-wrapper flex flex-col items-center justify-center py-[102px]">
          <div className="mb-[34px] text-center text-[30px] font-bold leading-[40px] text-[#151515] md:w-[830px] md:text-[38px] md:leading-[46px]">
            A Trusted Partner Committed to Delivering Exceptional Result
          </div>
          <div>
            <img src={ServiceDiagram} alt="" />
          </div>
        </div>
      </div>
      {/* Features section */}
      <div className="relative">
        <div className="content-wrapper h-[200px] pt-[50px] text-center text-3xl font-bold text-white md:h-[426px] md:pt-[102px]">
          All the Services you need
        </div>
        <div className="absolute  top-0 -z-10 h-[426px] w-full bg-[#1E1E1E]"></div>
        <div className="absolute left-[50%] top-[120px] w-[300px] translate-x-[-50%] bg-transparent md:top-[182px] md:w-[720px] ">
          <img src={Dashboard} alt="" height={581.39} />
        </div>

        <div className="bg-[#F8F8F8] pt-[250px] md:pt-[582px]">
          <div className="content-wrapper flex  flex-wrap justify-center gap-[42px] md:justify-normal">
            <div className="w-[315px]">
              <div className=" mb-4 flex justify-center">
                <img src={Feature_icon1} alt="" width={56} height={56} />
              </div>
              <div className="mb-3 text-center text-lg font-semibold">
                Custom Software Solutions
              </div>
              <div className="text-center text-base font-normal text-gray-800">
                Tailored applications built to meet your unique business needs
                and goals.
              </div>
            </div>
            <div className="w-[315px]">
              <div className="mb-4 flex justify-center">
                <img src={Feature_icon2} alt="" width={56} height={56} />
              </div>
              <div className="text-center text-lg font-semibold">
                Scalability and Performance
              </div>
              <div className="text-center text-base font-normal text-gray-800">
                Architect solutions designed to scale with your business and
                perform optimally under any load.
              </div>
            </div>
            <div className="w-[315px]">
              <div className="mb-4 flex justify-center">
                <img src={Feature_icon3} alt="" width={56} height={56} />
              </div>
              <div className="text-center text-lg font-semibold">
                Comprehensive Problem Solving
              </div>
              <div className="text-center text-base font-normal text-gray-800">
                Leverage our expertise to tackle complex technical challenges
                head-on.
              </div>
            </div>
            <div className="w-[315px]">
              <div className="mb-4 flex justify-center">
                <img src={Feature_icon4} alt="" width={56} height={56} />
              </div>
              <div className="mb-3 text-center text-lg font-semibold">
                Strategic Planning
              </div>
              <div className="text-center text-base font-normal text-gray-800">
                Develop a roadmap for your project or product with insights from
                seasoned CTOs and project managers.
              </div>
            </div>
            <div className="w-[315px]">
              <div className="mb-4 flex justify-center">
                <img src={Feature_icon5} alt="" width={56} height={56} />
              </div>
              <div className="text-center text-lg font-semibold">
                Technology Stack Selection
              </div>
              <div className="text-center text-base font-normal text-gray-800">
                Guidance in selecting the most suitable technologies for your
                project.
              </div>
            </div>
            <div className="w-[315px]">
              <div className="mb-4 flex justify-center">
                <img src={Feature_icon6} alt="" width={56} height={56} />
              </div>
              <div className="text-center text-lg font-semibold">
                Agile Development
              </div>
              <div className="text-center text-base font-normal text-gray-800">
                Embrace flexibility and efficiency through iterative development
                cycles.
              </div>
            </div>
            <div className="w-[315px]">
              <div className=" mb-4 flex justify-center">
                <img src={Feature_icon7} alt="" width={56} height={56} />
              </div>
              <div className="mb-3 text-center text-lg font-semibold">
                Product Optimization
              </div>
              <div className="text-center text-base font-normal text-gray-800">
                Continuous refinement and improvement to ensure your product
                stays competitive and relevant.
              </div>
            </div>
            <div className="w-[315px]">
              <div className="mb-4 flex justify-center ">
                <img src={Feature_icon8} alt="" width={56} height={56} />
              </div>
              <div className="text-center text-lg font-semibold">
                Quality Assurance
              </div>
              <div className="text-center text-base font-normal text-gray-800">
                Rigorous testing protocols to guarantee the reliability and
                functionality of your software.
              </div>
            </div>
            <div className="w-[315px]">
              <div className="mb-4 flex justify-center">
                <img src={Feature_icon9} alt="" width={56} height={56} />
              </div>
              <div className="text-center text-lg font-semibold">
                Expert Guidance
              </div>
              <div className="text-center text-base font-normal text-gray-800">
                Access to experienced CTOs and project managers for strategic
                advice and decision-making support.
              </div>
            </div>
            {/* <div className="flex">
            </div>
            <div className="flex">
            </div>
            <div className="flex">
            </div> */}
          </div>
        </div>
      </div>

      {/* Testemonials section */}
      {/* <div className="content-wrapper mb-[62px] flex w-[1030px] items-center justify-center py-[102px]">
        <div>
          <div className=" text-center text-[38px] font-bold leading-[46px] text-[#151515]">
            What our Happy User Says
          </div>
          <div className="card relative w-[505px]">
            <div className="qoute">
              <img src={QouteIcon} alt="" width={56} height={56} />
            </div>
            <div className="text-lg font-normal leading-[22px] text-[#232323]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequatur, sed? Veritatis, assumenda. Quas nemo minus laudantium
              at aliquid labore? Enim velit nam quos harum tempora consequatur
              laborum ipsum facere repellendus?
            </div>
          </div>
          <div className="client-wrapper flex w-[505px] items-center gap-3">
            <div>
              <img src={UserImg} alt="" width={44} height={44} />
            </div>
            <div>
              <div className="text-lg font-semibold leading-[22px] text-[#151515]">
                Amit Kumar
              </div>
              <div className="text-lg font-normal leading-[22px] text-[#232323]">
                CEO SprintMoney
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Contact us section */}
      <div className="mt-[62px]">
        <div className="content-wrapper  text-center  text-[38px] font-bold leading-[46px] text-[#151515]">
          Get in Touch
        </div>
        <div className="content-wrapper mb-[34px]  text-center text-[16px] font-normal leading-[19px] text-[#232323]">
          Partner with us to unlock new possibilities, streamline processes, and
          elevate your digital presence
        </div>
        <div className="content-wrapper">
          <div className="contactbox relative z-[999] flex flex-col p-[6px] md:min-h-[544px]  md:flex-row">
            <div className="rounded-lg bg-[#232323] md:w-[398px]">
              <div className="contact-form-bg h-[544px] w-auto pb-[52px] pl-8 pr-[46px] pt-[46px]">
                <div className=" mb-[34px] text-[24px] font-semibold leading-[29px] text-[#FFF]">
                  Contact Information
                </div>
                <div className="conatct-label mb-3">Office Address:</div>

                <div className="contact-details mb-8 ">
                  Northern Heights, SV Road,
                  <br />
                  Dahisar - East,
                  <br />
                  Mumbai, Maharashtra 400068, India
                </div>

                <div className="conatct-label mb-3 ">Email Address:</div>

                <div className="contact-details mb-8">company@scalix.in</div>

                <div className="conatct-label mb-3">Phone Number:</div>

                <div className="contact-details mb-[83px]">+91 9820216862</div>
                <div className="flex gap-4">
                  <div>
                    <img src={Instagram} alt="" width={24} height={24} />
                  </div>
                  <div>
                    <img src={Linkedin} alt="" width={24} height={24} />
                  </div>
                  <div>
                    <img src={Twitter} alt="" width={24} height={24} />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-8">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="mt-[20px] flex flex-col gap-5 md:flex-row">
                      <div>
                        <label htmlFor="firstName" className="form-label">
                          First Name
                        </label>
                        <Field
                          type="text"
                          id="firstName"
                          name="firstName"
                          className="form-field"
                          placeholder="First Name"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="mt-1 text-xs font-normal leading-3 text-[#FF4444]"
                        />
                      </div>
                      <div>
                        <label htmlFor="lastName" className="form-label">
                          Last Name
                        </label>
                        <Field
                          type="text"
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          className="form-field"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="mt-1 text-xs font-normal leading-3 text-[#FF4444]"
                        />
                      </div>
                    </div>
                    <div className="mt-[32px] flex flex-col gap-5 md:flex-row">
                      <div>
                        <label htmlFor="email" className="form-label">
                          Email Address
                        </label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                          className="form-field"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="mt-1 text-xs font-normal leading-3 text-[#FF4444]"
                        />
                      </div>
                      <div>
                        <label htmlFor="phone" className="form-label">
                          Phone Number
                        </label>
                        <Field
                          type="number"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          className="form-field"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="mt-1 text-xs font-normal leading-3 text-[#FF4444]"
                        />
                      </div>
                    </div>
                    <div className="mt-[32px]">
                      <label htmlFor="message" className="form-label">
                        Message
                      </label>
                      <Field
                        as="textarea"
                        rows="4"
                        cols="50"
                        type="message"
                        id="message"
                        name="message"
                        placeholder="Type Here..."
                        className="rounded-4 flex h-[132px] w-full bg-[#f2f2f4] p-3 md:w-[562px]"
                      />
                    </div>
                    <button
                      type="submit"
                      className="font-inria btn btn-primary mt-[32px] flex w-[190px] items-center justify-center gap-4 text-base leading-[19px]"
                    >
                      <div>Submit</div>
                      <div>
                        <img
                          src={MessageSquare}
                          alt=""
                          width={18}
                          height={18}
                        />
                      </div>
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-form-bg-cover h-[400px] md:h-[549px]">
        <div className="content-wrapper flex justify-center pt-[50px] md:mt-[-200px] md:justify-start md:pt-[302px]">
          <img src={ScalixSymbol} alt="" width={190} height={75} className="" />
        </div>
        <div className="content-wrapper mt-[34px] flex">
          <div className="flex flex-col md:flex-row">
            <div className="w-[400px] md:w-auto">
              <p className="text-center text-base font-medium text-white md:mr-[371px] ">
                © Scalix Enterprise Solutions LLP. All Rights Reserved.
              </p>
            </div>
            <div className="text-center">
              <span className="mr-[12px] text-center text-base font-medium text-[#ADADAD]">
                Terms & Conditions
              </span>

              <img
                src={Vector}
                alt=""
                width={2}
                height={19}
                style={{ strokeWidth: "1px", stroke: "#ADADAD" }}
                className="mr-[12px] inline"
              />

              <span className="text-center text-base font-medium text-[#ADADAD]">
                Privacy Policy
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
